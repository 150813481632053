import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

import {
  closeLoadingModal,
  openLoadingModal,
} from "../../../../store/Actions/loadingAction";

import {
  ContainerUnits,
  ContentGlobal,
  ManageUnits,
  Actions,
} from "../../styles";

import Unit from "../../../../components/Unit";
import Filter from "../../../../components/Filter";
import Api from "../../../../services/apiService";
import { toast } from "../../../../utils/toast";
import UnitModal from "./Modals/Unit";
import Button from "../../../../components/Forms/Button";

export default function Units() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [units, setUnits] = useState([]);
  const [isCreateUnit, setIsCreateUnit] = useState("none");
  const [searchStatus, setSearchStatus] = useState("0");
  const [refresh, setRefresh] = useState(true);
  const { user } = useSelector((state) => state.user);
  async function loadUnits({ status = "0" }) {
    try {
      dispatch(openLoadingModal());
      if(status === "0" || status === "2"){
        const { data } = await Api.get(`/unidades?status=${status}&limit=50`);
        setUnits([...data]);
      } else {
        const { data } = await Api.get("/unidades");
        const { data: inactiveUnits } = await Api.get(`/unidades?status=2&limit=50`);
        setUnits([...data, ...inactiveUnits]);
      }
    } catch (error) {
      toast(
        "error",
        "Erro",
        error?.response?.data?.message || "Não foi possível buscar as unidades."
      );
    }
    dispatch(closeLoadingModal());
    setRefresh(false);
  }

  // TODO: REFACTOR
  async function loadUnitByUser() {
    try {
      dispatch(openLoadingModal());
      const responses = await Promise.all(
        user.unidades.map((u) => Api.get(`unidades/${u.id}`))
      );
      setUnits(responses.map((response) => response.data));
    } catch (error) {
      toast(
        "error",
        "Erro",
        error?.response?.data?.message ||
          "Não foi possível buscar suas unidades."
      );
    }
    dispatch(closeLoadingModal());
    setRefresh(false);
  }

  async function deactivateUnit(id, event) {
    try {
      if (event) {
        event.cancelBubble = true;
        if (event.stopPropagation) event.stopPropagation();
      }
      dispatch(openLoadingModal());
      const { data } = await Api.delete(`/unidades/${id}`);
      toast(
        "success",
        "Sucesso",
        data?.message || "Unidade desativada com sucesso"
      );
      setRefresh(true);
    } catch (error) {
      toast(
        "error",
        "Erro",
        error?.response?.data?.message || "Não foi possível desativar a unidade"
      );
    }
    dispatch(closeLoadingModal());
  }

  async function activateUnit(id, event) {
    try {
      if (event) {
        event.cancelBubble = true;
        if (event.stopPropagation) event.stopPropagation();
      }
      dispatch(openLoadingModal());
      await Api.put(`/unidades/${id}`, { status: "0" });
      toast("success", "Sucesso", "Unidade ativada com sucesso");
      setRefresh(true);
    } catch (error) {
      toast(
        "error",
        "Erro",
        error?.response?.data?.message || "Não foi possível ativar a unidade"
      );
    }
    dispatch(closeLoadingModal());
  }

  useEffect(() => {
    if (refresh) {
      if (user.isadmin) {
        loadUnits(2);
      } else {
        loadUnitByUser();
      }
    }
  }, [refresh]);


  useEffect(() => {
    loadUnits({
      status: searchStatus,
    });
  }, [searchStatus]);

  function redirectUnitUpdate(id) {
    navigate(`unidade/${id}`);
  }

  function closeWindowModal() {
    setIsCreateUnit("none");
  }

  return (
    <ContentGlobal>
      <UnitModal
        setRefresh={setRefresh}
        isCreateUnit={isCreateUnit}
        closeWindowModal={closeWindowModal}
      />
      <ManageUnits style={{ marginTop: 5 }}>
        <Filter
          title="Unidades"
          searchStatusUnits={{
            searchableItens: [{ label: "status" }],
            handleSearchStatusUnits: (term) => setSearchStatus(term),
          }}
          actions={
            <Actions>
              <Button
                text="Cadastrar unidade"
                HandleClick={() => setIsCreateUnit("flex")}
              />
            </Actions>
          }
        >
          <ContainerUnits>
            {units.length > 0
              ? units.map((unit, index) => (
                  <Unit
                    key={index}
                    name={unit.unidade}
                    description={unit.desc_unidade}
                    statusUnit={unit.status === "0"}
                    handleClick={() => redirectUnitUpdate(unit.id)}
                    handleDeactivate={(e) =>
                      unit.status === "0"
                        ? deactivateUnit(unit.id, e)
                        : activateUnit(unit.id, e)
                    }
                  />
                ))
              : null}
          </ContainerUnits>
        </Filter>
      </ManageUnits>
    </ContentGlobal>
  );
}
